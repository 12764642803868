import React from 'react'
import { useTranslation } from 'react-i18next'
// store
import { routes } from '@routes'
import { API } from '@store/config'
// components
import ListView from '@components/ListView'
// module specific components
import LaunchNewRoomForm from '../components/forms/LaunchNewRoomForm'
import SuperField from '@components/forms/SuperField'
import HiringRoomCard from './HiringRoomCard'
import { Form } from 'semantic-ui-react'

const HiringRoomListView = () => {
    const { t } = useTranslation()

    return (
        <ListView
            as="card"
            allowSearch
            searchKey="name"
            // module="hiring_rooms"
            query={
                '&query={id, job_position, candidate_count, approval, date_from, date_to, is_active, name, target, hired_candidate_count, labels}&is_active=true'
            }
            endpoint={API.HIRING_ROOMS}
            settings={[
                {
                    permissions: [],
                    as: 'link',
                    linkTo: routes.HIRING_PROCESSES,
                    name: t('manage_hiring_processes'),
                    icon: 'settings-outline',
                },
            ]}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    name: t('launch_new_room'),
                    modal: <LaunchNewRoomForm btnName={t('launch')} />,
                    permissions: ['candidates.c_manage_recruitment'],
                },
                {
                    as: 'link',
                    type: 'detail',
                    icon: 'eye-outline',
                    name: t('view_card'),
                    redirect: (item) => routes.HIRING_ROOMS + item.id,
                    permissions: ['candidates.c_view_recruitment'],
                },
            ]}
            initialFilters={{
                job_position: '',
                labels: '',
                hrorder: '',
            }}
            renderFilterFields={(filters, setFilters, visible) => (
                <>
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice-select"
                            search
                            text="name"
                            label={t('order')}
                            value={filters.hrorder}
                            endpoint={API.ORDERS}
                            additionalFilters={'&only_basic_info=true&resource_type=hrorder'}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    hrorder: value,
                                })
                            }
                        />

                        <SuperField
                            as="choice-select"
                            search
                            value={filters.job_position}
                            endpoint={API.JOB_POSITIONS}
                            additionalFilters={'&query={id, title}'}
                            text="title"
                            label={t('position')}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    job_position: value,
                                })
                            }
                        />
                    </Form.Group>

                    <SuperField
                        as="choice-select"
                        search
                        multiple
                        value={filters.labels}
                        label={t('labels')}
                        endpoint={API.CANDIDATES_LABELS}
                        text="name"
                        onChange={(e, { value }) =>
                            setFilters({
                                ...filters,
                                labels: value,
                            })
                        }
                    />
                </>
            )}
            listAdditionActions={(data, setData, total, setTotal, response, filters) => [
                {
                    as: 'filter',
                    index: 0,
                    name: t('active'),
                    filters: {
                        is_active: true,
                    },
                    // count: response.count || 0
                },
                {
                    as: 'filter',
                    index: 1,
                    name: t('closed'),
                    filters: {
                        is_active: false,
                    },
                    // count: response.count || 0
                },
            ]}
            itemsPerRow={4}
            renderCard={(data, setData, setTotal, actions) => (
                <>
                    {data?.map((item) => (
                        <HiringRoomCard
                            key={item.id}
                            item={item}
                            actions={actions}
                            setData={setData}
                            setTotal={setTotal}
                        />
                    ))}
                </>
            )}
        />
    )
}

export default HiringRoomListView
