import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
//stores
import { hiringService } from '@services/ServiceHiring'
import { isEmpty } from '@helpers/validation'
import { API } from '@store/config'
import { routes } from '@routes'
import { getRequest } from '@services/ServiceCommon'
// components
import { Form, Message, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import CanView from '@components/perms/CanView'
// others
import moment from 'moment'

const LaunchNewRoomForm = ({ room, onClose, onConfirm, btnName }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [isProcessing, setIsProcessing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [errorList, setErrorList] = useState([])
    const reduxUser = useSelector((state) => state.user.profile_id)

    const [hiringRoom, setHiringRoom] = useState({
        name: (room && room.name && room.name) || '',
        process: '',
        jobPosition: (room && room.job_position && room.job_position.id) || '',
        hrorder: (room && room.hrorder && room.hrorder.id) || '',
        dateFrom: (room && room.date_from && room.date_from) || '',
        dateTo: (room && room.date_to && room.date_to) || '',
        active: (room && room.is_active && room.is_active) || true,
        target: (room && room.target && room.target) || null,
    })

    const [orders, setOrders] = useState([])
    const [invalidDateRange, setInvalidDateRange] = useState(false)
    const [labels, setLabels] = useState(room?.labels?.length > 0 ? room?.labels?.map((item) => item.id) || [] : [])

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            const request = await getRequest(
                API.ORDERS + '?query={id, name, target, date_from, date_to}&resource_type=hrorder&is_active=true'
            )

            if (request.status === 200) {
                let options = []
                for (let i = 0; i < request.response.length; i++) {
                    options.push({
                        key: request.response[i].id,
                        value: request.response[i].id,
                        text: request.response[i].name,
                        target: request.response[i].amount,
                        date_from: request.response[i].start_date,
                        date_to: request.response[i].end_date,
                    })
                }

                setOrders(options)
            }
            setIsLoading(false)
        }

        fetchData()
        // eslint-disable-next-line
    }, [])

    // Validate Form
    useEffect(() => {
        if (hiringRoom.dateFrom !== '' && hiringRoom.dateTo !== '') {
            if (moment(hiringRoom.dateFrom).isAfter(hiringRoom.dateTo)) {
                setInvalidDateRange(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRange(false)
            }
        }
        // eslint-disable-next-line
    }, [hiringRoom])

    const handleSubmit = async () => {
        setIsProcessing(true)
        setErrorList([])

        let data = {
            name: hiringRoom.name,
            date_from: hiringRoom.dateFrom !== '' ? hiringRoom.dateFrom : null,
            date_to: hiringRoom.dateTo !== '' ? hiringRoom.dateTo : null,
            job_position: hiringRoom.jobPosition !== '' ? hiringRoom.jobPosition : null,
            hrorder: hiringRoom.hrorder !== '' ? hiringRoom.hrorder : null,
            is_active: hiringRoom.active,
            target: hiringRoom.target,
            recruiters: { add: [reduxUser] },
        }

        if (room?.id === undefined) {
            if (labels.length > 0) data['labels'] = { add: labels }
        } else {
            data['labels'] = {
                remove: room?.labels.map((item) => item.id) || [],
                add: labels,
            }
        }

        let request = undefined
        if (room) {
            request = await hiringService.updateHiring(room.id, data)
        } else {
            request = await hiringService.launchNewHiring({ ...data, process: hiringRoom.process })
        }

        if (request && [200, 201].includes(request.status)) {
            if (request.status === 201) {
                history.push(routes.HIRING_ROOMS + request.response.id)
            } else {
                onConfirm(request.response)
                onClose()
            }
        } else {
            if (request.response.job_position) {
                setErrorList([...errorList, request.response.job_position?.[0]])
            } else {
                setErrorList([...errorList, request.response.detail])
            }
        }

        setIsProcessing(false)
    }

    const autoCompleteData = (value) => {
        setHiringRoom((prev) => ({
            ...prev,
            hrorder: value,
        }))

        const selected_order = orders.find((order) => order.key === value)

        if (selected_order !== undefined) {
            setHiringRoom((prev) => ({
                ...prev,
                name: selected_order.text,
                target: selected_order.target,
                dateFrom: selected_order.date_from,
                dateTo: selected_order.date_to,
            }))
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            {errorList.length > 0 && (
                <Message negative>
                    {errorList.map((error, index) => (
                        <p key={index}> {error} </p>
                    ))}
                </Message>
            )}

            <SuperField
                as="input"
                autoFocus
                required
                label={t('name')}
                placeholder={t('name')}
                value={hiringRoom.name}
                onChange={(e) =>
                    setHiringRoom({
                        ...hiringRoom,
                        name: e.target.value,
                    })
                }
            />

            <Divider />

            <CanView permissions={['orders.c_view_order']}>
                <SuperField
                    as="choice"
                    search
                    label={t('order')}
                    help={t('help_hrorder')}
                    loading={isLoading}
                    disabled={isLoading}
                    customOptions={orders}
                    value={hiringRoom.hrorder}
                    onChange={(e, { value }) => autoCompleteData(value)}
                />
                <Divider />
            </CanView>

            <Form.Group widths={'equal'}>
                <CanView permissions={['processes.c_view_processes']}>
                    {!room && (
                        <SuperField
                            as="choice-select"
                            required
                            search
                            text="name"
                            label={t('process')}
                            endpoint={API.PROCESSES}
                            additionalFilters={'&query={id, name, process_type}&is_active=true'}
                            value={hiringRoom.process}
                            onChange={(e, { value }) =>
                                setHiringRoom({
                                    ...hiringRoom,
                                    process: value,
                                })
                            }
                        />
                    )}
                </CanView>
                <CanView permissions={['jobs.c_view_job_positions']}>
                    <SuperField
                        as="choice-select"
                        search
                        text="title"
                        label={t('position')}
                        endpoint={API.JOB_POSITIONS}
                        additionalFilters={'&query={id, title}'}
                        value={hiringRoom.jobPosition}
                        onChange={(e, { value }) =>
                            setHiringRoom({
                                ...hiringRoom,
                                jobPosition: value,
                            })
                        }
                        initialOptions={{
                            attribute: 'title',
                            source: room?.job_position,
                        }}
                    />
                </CanView>
            </Form.Group>

            <Form.Group widths={2}>
                <SuperField
                    as="datepicker"
                    label={t('date_from')}
                    value={hiringRoom.dateFrom}
                    onChange={(e, value) =>
                        setHiringRoom({
                            ...hiringRoom,
                            dateFrom: value.value,
                        })
                    }
                />
                <SuperField
                    as="datepicker"
                    label={t('date_to')}
                    value={hiringRoom.dateTo}
                    error={invalidDateRange}
                    onChange={(e, value) =>
                        setHiringRoom({
                            ...hiringRoom,
                            dateTo: value.value,
                        })
                    }
                />

                <SuperField
                    as="input"
                    type="number"
                    min="1"
                    width="4"
                    pattern="[0-9]*"
                    label={t('target')}
                    value={hiringRoom.target}
                    onChange={(e, { value }) =>
                        setHiringRoom({
                            ...hiringRoom,
                            target: value,
                        })
                    }
                />
            </Form.Group>

            <SuperField
                as="choice-select"
                search
                multiple
                value={labels}
                text="name"
                settings={'types/hiring-rooms-labels/'}
                endpoint={API.CANDIDATES_LABELS}
                label={t('labels')}
                onChange={(e, { value }) => setLabels(value)}
                initialOptions={{
                    attribute: 'name',
                    source: room?.labels,
                }}
            />

            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    disabled={
                        isProcessing ||
                        (room?.id === undefined && isEmpty(hiringRoom.process)) ||
                        isEmpty(hiringRoom.name) ||
                        invalidDateRange !== false
                    }
                    loading={isProcessing}
                    text={btnName}
                />
            </Form.Field>
        </Form>
    )
}

export default LaunchNewRoomForm
