import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import DocPreview from '@components/DocPreview'
import SuperField from '@components/forms/SuperField'
import ProfileCard from '@components/general/ProfileCard'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import ContactCell from '../../candidates/components/tables/ContactCell'

const ReactionsListView = ({ params, setRoom }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    const GetFullname = ({ data }) => {
        return (
            <p>
                {(data?.titles_before ? data?.titles_before + ' ' : '') +
                    data?.firstname +
                    ' ' +
                    data?.lastname +
                    ' ' +
                    (data?.titles_after ? data?.titles_after : '')}
            </p>
        )
    }

    const onReject = async (item, setData) => {
        const request = await requests.patch(API.PUBLICATION_REACTIONS + item.uuid + '/', { is_rejected: true })

        if (request.status === 200) {
            setData((prevState) =>
                prevState.filter((data) => {
                    if (data.id === item.id) {
                        data.is_rejected = true
                    }

                    return data
                })
            )

            if (request.response.is_rejected === true && request.response.profile?.id === undefined) {
                setRoom((prev) => ({ ...prev, new_reactions_count: prev.new_reactions_count - 1 }))
            }

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('success'),
                description: t('reject_reaction_message'),
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    const onUndoReject = async (item, setData) => {
        const request = await requests.patch(API.PUBLICATION_REACTIONS + item.uuid + '/', { is_rejected: false })

        if (request.status === 200) {
            setData((prevState) =>
                prevState.filter((data) => {
                    if (data.id === item.id) {
                        data.is_rejected = false
                    }

                    return data
                })
            )

            if (request.response.is_rejected === false && request.response.profile?.id === undefined) {
                setRoom((prev) => ({ ...prev, new_reactions_count: prev.new_reactions_count + 1 }))
            }

            //setRejected((prev) => prev.filter((data) => data.id !== item.id))

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('success'),
                description: t('undo_rejection_message'),
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    const onApply = async (item, setData) => {
        const request = await requests.post(API.PUBLICATION_REACTIONS + item.id + '/convert_to_profile/', {})

        if (request.status === 201) {
            const requestAssign = await requests.post(
                API.CANDIDATES + request.response.id + '/assign_to_recruitment/',
                { recruitment: params.id }
            )
            if (requestAssign.status === 200) {
                setData((prevState) =>
                    prevState.filter((reaction) => {
                        if (reaction.id === item.id) {
                            reaction.profile = request.response.id
                            reaction.status = false
                        }

                        return reaction
                    })
                )

                if (request.response.is_rejected === false && request.response.profile?.id !== undefined) {
                    setRoom((prev) => ({
                        ...prev,
                        new_reactions_count: prev.new_reactions_count - 1,
                        candidate_count: prev.candidate_count + 1,
                    }))
                }

                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('success'),
                    description: t('convert_to_profile_success_message'),
                    animation: 'bounce',
                    time: 5000,
                })
            }
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: t('error'),
                description: t('convert_to_profile_error_message'),
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    return (
        <>
            <ListView
                as="table"
                endpoint={API.PUBLICATION_REACTIONS}
                initialFilters={{
                    recruitment: params.id,
                    is_rejected: false,
                    is_new: true,
                    show_candidates_with_cv: '',
                }}
                actionsCellWidth="2"
                actions={[
                    {
                        as: 'modal',
                        type: 'custom',
                        icon: 'eye-outline',
                        customIconColor: 'var(--primary)',
                        name: t('detail'),
                        modalSize: 'tiny',
                        modal: (reaction) => <ProfileCard candidateData={reaction.data} />,
                    },
                    {
                        as: 'confirm',
                        type: 'custom',
                        name: t('accept'),
                        customIconColor: 'var(--blue)',
                        icon: 'checkmark-done-outline',
                        text: t('would_you_like_to_convert_this_reaction_to_candidate'),
                        onClick: async (item, setData, setTotal) => onApply(item, setData),
                        isHidden: (item) => item.profile?.id !== undefined || item.is_rejected === true,
                    },
                    {
                        as: 'confirm',
                        type: 'custom',
                        name: t('reject'),
                        customIconColor: 'var(--danger)',
                        icon: 'close-circle-outline',
                        text: t('reject_react'),
                        onClick: async (item, setData) => onReject(item, setData),
                        isHidden: (item) => item.is_rejected === true || item.profile?.id !== undefined,
                    },
                    {
                        as: 'confirm',
                        type: 'custom',
                        name: t('undo_reject'),
                        customIconColor: 'var(--blue)',
                        icon: 'arrow-undo-outline',
                        text: t('undoreject'),
                        onClick: async (item, setData) => onUndoReject(item, setData),
                        isHidden: (item) => item.is_rejected === false || item.profile?.id !== undefined,
                    },
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <SuperField
                            as="checkbox"
                            label={t('show_reactions_with_cv')}
                            checked={filters.show_candidates_with_cv}
                            onChange={() =>
                                setFilters((prev) => ({
                                    ...prev,
                                    show_candidates_with_cv: filters.show_candidates_with_cv === true ? '' : true,
                                }))
                            }
                        />
                    </>
                )}
                tableHeaders={[
                    { title: t('fullname') },
                    { title: t('contact') },
                    { title: t('cv') },
                    { title: t('gdpr') },
                    { title: t('created_on') },
                    { title: t('status') },
                ]}
                listAdditionActions={(data, setData, total, setTotal, response) => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('new'),
                        filters: {
                            is_rejected: false,
                            is_new: true,
                        },
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('accepted'),
                        filters: {
                            is_rejected: false,
                            is_new: false,
                        },
                    },
                    {
                        as: 'filter',
                        index: 2,
                        name: t('rejected_reaction'),
                        filters: {
                            is_rejected: true,
                            is_new: null,
                        },
                    },
                    {
                        as: 'filter',
                        index: 3,
                        name: t('all'),
                        filters: {
                            is_rejected: null,
                            is_new: null,
                        },
                    },
                ]}
                renderCells={(reaction) => [
                    {
                        content: reaction.profile?.id ? (
                            <Link to={routes.CANDIDATES_CARD + reaction.profile?.id} target="_blank">
                                <GetFullname data={reaction?.data} />
                            </Link>
                        ) : (
                            <GetFullname data={reaction?.data} />
                        ),
                    },
                    {
                        content: (
                            <ContactCell
                                row={{
                                    phone: reaction?.data?.contacts?.create?.[0]?.phone,
                                    email: reaction?.data?.contacts?.create?.[0]?.email,
                                }}
                            />
                        ),
                    },
                    {
                        content: reaction.attachments.find((item) => item.type === 'cv') ? (
                            <SuperDuperModal
                                size="large"
                                trigger={
                                    <Icon
                                        title={t('attachment')}
                                        name="document-text-outline"
                                        style={{
                                            cursor: 'pointer',
                                            color: 'var(--dark)',
                                            fontSize: '1.5rem',
                                        }}
                                    />
                                }
                                content={
                                    <DocPreview uri={reaction.attachments.find((item) => item.type === 'cv')?.file} />
                                }
                            />
                        ) : (
                            '--'
                        ),
                    },
                    { content: t('yes') },
                    {
                        content: reaction?.created_on
                            ? tzDateTime(reaction?.created_on).format(dateFormat + ' HH:mm')
                            : '',
                    },
                    {
                        content: (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                {reaction.is_rejected ? (
                                    <>
                                        <Icon
                                            name="ellipse"
                                            style={{
                                                color: 'var(--danger)',
                                                marginRight: '0.5rem',
                                            }}
                                        />
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('rejected')}</span>
                                    </>
                                ) : reaction.profile?.id ? (
                                    <>
                                        <Icon
                                            name="ellipse"
                                            style={{
                                                color: 'var(--success)',
                                                marginRight: '0.5rem',
                                            }}
                                        />
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('accepted')}</span>
                                    </>
                                ) : (
                                    <>
                                        <Icon
                                            name="ellipse"
                                            style={{
                                                color: 'var(--blue)',
                                                marginRight: '0.5rem',
                                            }}
                                        />
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('new')}</span>
                                    </>
                                )}
                            </div>
                        ),
                    },
                ]}
            />
        </>
    )
}

export default ReactionsListView
